

















































































































































import Vue from "vue";
import { Messages } from "@/models/enums/messages.enum";
import { userServices } from "@/services/user.services";
import {
  ResponseListOfUsers,
  DataUser,
  RequestChangePasswordAdmin,
  ResponseDetailUserAuth,
} from "@/models/interface/user.interface";
import {
  PropsModel,
  ResponsePagination,
} from "@/models/constant/interface/common.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { getauthorities } from "@/helpers/cookie";
import { checkPasswordStrength } from "@/validator/globalvalidator";
import router from "@/router";
import { mapGetters, mapState } from "vuex";
import { ROLE } from "@/models/enums/user.enum";
export default Vue.extend({
  name: "ListUser",
  data() {
    return {
      authPrivilege: [] as string[],
      loadingTable: false as boolean,
      direction: "" as string,
      sort: "" as string,
      search: "" as string,
      limit: 10 as number,
      page: 1 as number,
      totalData: 0 as number,
      payloadPagination: {},
      defaultPagination: false as boolean,
      modalCreateId: false as boolean,
      visibleModalChangePassword: false as boolean,
      userId: "" as string,
      columns: [
        {
          title: this.$t("lbl_email"),
          dataIndex: "email",
          key: "email",
          width: 200,
          sorter: true,
        },
        {
          title: this.$t("lbl_active"),
          dataIndex: "active",
          key: "active",
          sorter: true,
          width: 120,
          scopedSlots: { customRender: "isEnable" },
        },
        {
          title: this.$t("lbl_signin_username"),
          dataIndex: "username",
          key: "username",
          sorter: true,
          width: 200,
        },
        {
          title: this.$t("lbl_action").toString(),
          dataIndex: "operation",
          width: "120px",
          align: "center",
          scopedSlots: { customRender: "operation" },
          button: ["update"],
          responsiveMenu: [] as { title; icon; triggerMenu }[],
        },
      ] as PropsModel[],
      dataSource: [] as DataUser[],
      loading: {
        submit: false,
      },
      formChangePassword: this.$form.createForm(this, {
        name: "changePassword",
      }),
      formRulesChangePassword: {
        username: {
          label: "lbl_signin_username",
          name: "username",
          placeholder: "lbl_signin_username_placeholder",
          decorator: ["username"],
        },
        newPassword: {
          label: "lbl_new_password",
          name: "newPassword",
          placeholder: "lbl_new_password_placeholder",
          decorator: [
            "newPassword",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
                {
                  validator: checkPasswordStrength,
                },
              ],
            },
          ],
        },
        confirmPassword: {
          label: "lbl_confirm_password",
          name: "confirmPassword",
          placeholder: "lbl_confirm_password_placeholder",
          decorator: [
            "confirmPassword",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
                {
                  validator: this.customValidator,
                },
              ],
            },
          ],
        },
      },
    };
  },
  watch: {
    currAuth: {
      immediate: true,
      handler: "onChangeAuth",
    },
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
    },
    ...mapState({
      currAuth: (st: any) => st.authStore.authData as ResponseDetailUserAuth,
    }),
    ...mapGetters({
      getUserRoles: "authStore/GET_USER_ROLE",
    }),
    isAdmin(): boolean {
      return this.getUserRoles.find(
        (role: string) => role === ROLE.ADMINISTRATOR
      );
    },
  },
  beforeCreate() {
    this.formChangePassword = this.$form.createForm(this, {
      name: "changePassword",
    });
  },
  mounted() {
    this.getListOfUsers();
  },
  methods: {
    onChangeAuth(newValue): void {
      if (!newValue) return;
      const auth = getauthorities();
      const dataAuth = auth.find(data => data.name === "user");
      if (!dataAuth) return;
      this.authPrivilege = dataAuth.value;
      const colAction = this.columns[3];
      if (this.isAdmin) {
        colAction.responsiveMenu.push({
          title: this.$t("lbl_profile_change_password"),
          icon: "edit",
          triggerMenu: this.handleChangePassword,
        });
      }
    },
    handleCLickFLoating() {
      router.push("/user/create");
    },
    resetFilter(): void {
      this.direction = "";
      this.search = "";
      this.page = 1;
      this.getListOfUsers();
    },
    onSorterChange(response): void {
      this.direction = response.sort.order === "ascend" ? "asc" : "desc";
      this.sort = `${response.sort.field}:${this.direction}`;
      this.getListOfUsers();
    },
    handleChangePassword(record) {
      this.visibleModalChangePassword = true;
      this.userId = record.id;
      this.formChangePassword.setFieldsValue({
        username: record.username,
      });
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      this.getListOfUsers();
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      this.getListOfUsers();
    },
    reponseDeleteTable(response): void {
      userServices
        .deleteUser(response.data.id)
        .then((res: boolean) => {
          if (res) {
            this.$notification.success({
              description: Messages.DELETE_SUCCESS,
              message: "Success",
              duration: 30,
            });
            this.getListOfUsers();
          }
        })
        .catch(() =>
          this.$notification.error({
            description: Messages.DELETE_FAIL,
            message: "Error",
            duration: 30,
          })
        );
    },
    reponseEditTable(response): void {
      const id = response.data.id;
      this.$router.push({ path: `./update/${id}` });
    },
    reponseSearchInput(response): void {
      if (response)
        this.search = "email~*" + response + "*_OR_username~*" + response + "*";
      else this.search = "";
      this.page = 1;
      this.getListOfUsers();
    },
    handleCancel(): void {
      this.modalCreateId = false;
      this.visibleModalChangePassword = false;
    },
    getListOfUsers(): void {
      let params = {
        limit: this.limit,
        page: this.page - 1,
      } as RequestQueryParamsModel;
      if (this.search) params.search = this.search;
      if (this.direction) params.sorts = this.sort;
      this.loadingTable = true;
      userServices
        .listOfUsers(params)
        .then((res: ResponseListOfUsers) => {
          this.totalData = res.totalElements;
          res.data.forEach((dataObject, index) => (dataObject.key = index));
          this.dataSource = res.data;
        })
        .finally(() => (this.loadingTable = false));
      this.modalCreateId = false;
    },
    submitFormChangePassword(e: Event): void {
      e.preventDefault();
      this.formChangePassword.validateFields((err, values) => {
        if (err || this.loading.submit) return;
        this.loading.submit = true;
        const payload: RequestChangePasswordAdmin = {
          userId: this.userId,
          newPassword: values.newPassword,
          confirmPassword: values.confirmPassword,
        };
        userServices
          .changePasswordAdmin(payload)
          .then(value => {
            if (value) {
              this.visibleModalChangePassword = false;
              this.$notification.success({
                description: Messages.CHANGE_PASSWORD_SUCCESS,
                message: "Success",
                duration: 30,
              });
              this.formChangePassword.resetFields();
            }
          })
          .finally(() => (this.loading.submit = false));
      });
    },
    customValidator(_rule, value, callback) {
      if (value) {
        const newPassword =
          this.formChangePassword.getFieldValue("newPassword");
        if (newPassword === value) {
          callback();
        } else {
          callback("Password doesn't match");
        }
      } else {
        callback();
      }
    },
  },
});
